<template>
  <div class="tab">
    <!-- 左侧图片 -->
    <div class="left">
      <img src="@/assets/denglu/login-bg.jpg" />
    </div>
    <!-- 表单控件 -->
    <div class="FormModel">
      <img :src="logoPath" />
      <!-- <p class="p">
        <b>注册</b>
      </p> -->
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        labelAlign="left"
        :wrapper-col="wrapperCol"
      >
        <!-- 所在省 -->
        <!-- <a-form-model-item
          label="注册区域"
          :labelCol="{
            span: 9
          }"
          :wrapper-col="{
            span:15
          }"
          prop="province"
          style="display: inline-block; margin-right: 20px; width: 240px"
        >-->
        <!-- <a-select
            :options="options"
            @change="changeCity"
            allowClear
            v-model="form.province"
            placeholder="请选择省份"
          ></a-select>
        </a-form-model-item>-->

        <a-form-model-item
          :labelCol="{
            span: 5,
          }"
          :wrapper-col="{
            span: 19,
          }"
          label="省市区域"
          prop="location"
        >
          <a-cascader
            :options="locationTree"
            v-model="form.location"
            placeholder="请选择省市区域"
            :fieldNames="{
              label: 'district',
              value: 'district',
              children: 'children',
            }"
          />
        </a-form-model-item>

        <!-- 所在市 -->
        <!-- <a-form-model-item label prop="city" style="display: inline-block; width: 150px">
          <a-select
            allowClear
            :options="cityList"
            @change="changeDistrict"
            placeholder="请选择市区"
            v-model="form.city"
          ></a-select>
        </a-form-model-item>-->

        <!-- <a-form-model-item label="所在区" prop="county">
          <a-select
            ref="select"
            :options="districtList"
            placeholder="请选择"
            v-model="form.county"
          ><a-select>
        </a-select></a-select></a-form-model-item>-->

        <!-- <a-form-model-item ref="code" label="经纪人服务码" prop="code">
              <a-input
                :maxLength="6"
                placeholder="请输入经纪人服务码"
                v-model="form.code"
                @blur="
                  () => {
                    $refs.code.onFieldBlur();
                  }
                "
              />
        </a-form-model-item>-->
        <a-form-model-item
          label="服务码（选填）"
          :labelCol="{
            span: 7,
          }"
          :wrapper-col="{
            span: 17,
          }"
          prop="code"
        >
          <a-input placeholder="服务码（选填）" v-model="form.code"></a-input>
        </a-form-model-item>
        <a-form-model-item ref="phone" prop="phone">
          <a-input-search
            allow-clear
            placeholder="手机号"
            v-model="form.phone"
            @search="onSearch"
            @blur="
              () => {
                $refs.phone.onFieldBlur()
              }
            "
          >
            <a-button slot="enterButton" :disabled="isDisabled">{{ buttonName }}</a-button>
          </a-input-search>
          <!-- <a-input
            placeholder="手机号"
            v-model="form.phone"
            @blur="
              () => {
                $refs.phone.onFieldBlur()
              }
            "
          ></a-input>-->
        </a-form-model-item>

        <a-form-model-item ref="checkCode" prop="checkCode">
          <a-input
            allow-clear
            placeholder="短信验证码"
            v-model="form.checkCode"
            @blur="
              () => {
                $refs.checkCode.onFieldBlur()
              }
            "
          ></a-input>

          <!-- <a-button class="code_btn" @click="send_captcha" :disabled="isDisabled">{{ buttonName }}</a-button> -->
        </a-form-model-item>
        <a-form-model-item ref="password" prop="password">
          <a-input-password
            type="password"
            allow-clear
            placeholder="输入新密码"
            v-model="form.password"
          ></a-input-password>
        </a-form-model-item>
        <a-form-model-item ref="passwordAgain" prop="passwordAgain">
          <a-input-password
            allow-clear
            type="password"
            placeholder="确认新密码"
            v-model="form.passwordAgain"
            @blur="
              () => {
                $refs.passwordAgain.onFieldBlur()
              }
            "
          ></a-input-password>
        </a-form-model-item>

        <a-form-model-item>
          <a-row type="flex" justify="center">
            <a-col :span="24" class="position_row">
              <div class="register_checkbox">
                <a-row>
                  <a-col :span="2">
                    <a-checkbox v-model="isChoose" class="checkbox"></a-checkbox>
                  </a-col>
                  <a-col :span="22">
                    <div class="register_tips">
                      本人已认真阅读并认可
                      <a
                        href="https://app.agw-gcy.net/sgb/用户服务协议.html"
                        target="_blank"
                        style="color: #1677ff"
                      >《用户服务协议》</a
                      >及
                      <a
                        href="https://app.agw-gcy.net/sgb/增值服务协议.html"
                        target="_blank"
                        style="color: #1677ff"
                      >《增值服务协议》</a
                      >
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-col>
            <a-col :span="24">
              <div class="register_btn">
                <a-row>
                  <a-col :span="12">
                    <router-link to="/login">
                      <div class="register_btn_text">返回登录页</div>
                    </router-link>
                  </a-col>
                  <a-col :span="12">
                    <a-button
                      type="primary"
                      style="width: 200px; height: 40px"
                      :loading="btnLoading"
                      @click="onSubmit"
                    >提交注册</a-button
                    >
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { queryAllDistrict, queryAllProvince } from '@/api/materialShop'
import { getTree } from '@/api/location.js'
import { getSmsCaptcha, register } from '@/api/login'
import { Cascader } from 'ant-design-vue'
import VInput from './v-input.vue'
import reg from '@/utils/reg'
export default {
  components: {
    'a-cascader': Cascader,
    VInput
  },
  data () {
    return {
      options: [],
      cityList: [],
      districtList: [],
      current: 0,
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
      CountDown: 60, // 倒计时
      buttonName: '发送验证码', // 按钮初始化文字
      isDisabled: false, // 是否禁用按钮
      form: {},
      isChoose: false,
      rules: {
        checkCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: reg.password.pattern, message: reg.password.msg }
        ],
        passwordAgain: [
          { required: true, message: '请再次确认密码', trigger: 'blur' },
          { validator: this.validPasswordAgain, trigger: 'blur' }
        ],
        phone: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        { pattern: reg.phone.pattern, message: reg.phone.msg }
        ],
        location: [{ required: true, message: '请选择省市区域', trigger: 'change' }],
        // county: [{ required: true, message: '请选择区', trigger: 'change' }],
        province: [{ required: true, message: '请选择省份', trigger: 'change' }],
        city: [{ required: true, message: '请选择市区', trigger: 'change' }]
      },
      steps: [
        {
          title: 'First',
          content: 'First-content'
        },
        {
          title: 'Second',
          content: 'Second-content'
        },
        {
          title: 'Last',
          content: 'Last-content'
        }
      ],
      locationTree: [],
      btnLoading: false,
      logoPath: ''
    }
  },
  mounted () {
    const logoName = process.env.NODE_ENV === 'development' ? 'logo-dev' : 'logo'
    this.logoPath = require(`@/assets/denglu/${logoName}.png`)
    queryAllProvince()
      .then((res) => {
        res.forEach((v) => {
          v.value = v.district
          v.label = v.district
        })
        this.options = res
      })
      .catch((err) => {
        console.log(err)
      })
    getTree().then((res) => {
      this.locationTree = res
    })
  },
  methods: {
    codeValid (rule, value, callback) {
      if (!value) {
        callback()
      } else {
        const pattern = /[0-9A-Za-z]{6}/
        if (pattern.test(value)) {
          callback()
        } else {
          callback(new Error('请输入六位数字或字母'))
        }
      }
    },
    validPasswordAgain (rule, value, callback) {
      if (!this.form.password) {
        callback(new Error('请先输入密码'))
      } else {
        if (this.form.password !== value) {
          callback(new Error('两次密码不一致，请重新输入'))
        } else {
          callback()
        }
      }
    },
    onSearch () {
      const phoneReg = /^1[3456789]\d{9}$/
      if (this.form.phone) {
        if (phoneReg.test(this.form.phone)) {
          this.isDisabled = true
          const timerId = setInterval(() => {
            this.CountDown--
            this.buttonName = this.CountDown + '秒后重试'
            if (this.CountDown <= 0) {
              clearInterval(timerId)
              this.buttonName = '重新发送'
              this.CountDown = 60
              this.isDisabled = false
            }
          }, 1000)
          const params = {
            phone: this.form.phone,
            configId: 1
          }
          getSmsCaptcha(params)
            .then((res) => {
              if (res.success) {
                this.$message.success('发送成功')
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          this.$message.error('请检查手机号是否正确')
        }
      } else {
        this.$message.error('请输入手机号')
      }
    },
    changeCity (value, option) {
      this.$set(this.form, 'city', undefined)
      this.cityList = []
      if (value) {
        const params = {
          pid: option.data.props.id
        }
        queryAllDistrict(params)
          .then((res) => {
            res.forEach((v) => {
              v.value = v.district
              v.label = v.district
            })
            this.cityList = res
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 获取区
    changeDistrict (value, option) {
      if (value) {
        const params = {
          pid: option.data.props.id
        }
        queryAllDistrict(params)
          .then((res) => {
            res.forEach((v) => {
              v.value = v.district
              v.label = v.district
            })
            this.districtList = res
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    onSubmit () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.isChoose) {
            this.$message.success('请先阅读并认可 《增值服务协议》及 《用户服务协议》')
            return
          }
          this.btnLoading = true
          register({
            ...this.form,
            // location: this.form.province + this.form.city,
            location: this.form.location.join('-'),
            username: this.form.phone
          })
            .then((res) => {
              if (res.success) {
                this.$message.success('注册成功')
                this.$router.push('/login')
              } else {
                this.$message.warning(res.errMessage)
              }
            })
            .finally(() => {
              this.btnLoading = false
            })
        }
      })
    },
    resetForm () {
      this.$refs.ruleForm.resetFields()
    },
    next () {
      this.current++
    },
    prev () {
      this.current--
    }
  }
}
</script>
<style lang="less" scoped>
.position_row {
  position: relative;
  top: -10px;
}
.p {
  margin-bottom: 5px;
  width: 74px;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 18px;
  color: #333333;
  opacity: 1;
}
</style>
<style lang="less">
//发送验证码位置调整
.code_btn {
  height: 20px;
  position: absolute;
  top: -4px;
  right: 18px;
  font-size: 18px;
  border: 0;
  color: #1677ff;
}
.register_tips {
  font-size: 12px;
  margin-left: 10px;
}
.register_btn {
  display: flex;
  justify-content: space-around;
}
.register_btn_text {
  width: 100px;
  height: 40px;
}
.tab {
  width: 1100px;
  height: 610px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .left img {
    width: 500px;
    height: 610px;
    float: left;
  }
  .FormModel {
    width: 600px;
    height: 610px;
    padding: 0 40px;
    background: #ffffff;
    border-radius: 0 8px 8px 0;
    float: left;
    .ant-form-item-label-left {
      height: 40px;
      label {
        font-size: 16px;
        font-weight: 550;
        color: #333333;
      }
    }
    .ant-form-item-required {
      &::before {
        display: none;
      }
    }
    img {
      display: block;
      width: 300px;
      height: 64.47px;
      margin: 33px auto 20px;
    }
  }
}

.checkbox {
  width: 4%;
}
</style>
