<template>
  <div class="tab forget__wrapper">
    <!-- 左侧图片 -->
    <div class="left"><img src="@/assets/denglu/login-bg.jpg" /></div>
    <!-- 表单控件 -->
    <div class="FormModel">
      <img :src="logoPath" />
      <p class="p"><b>找回密码</b></p>
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item ref="phone" prop="username">
          <a-input-search
            allow-clear
            placeholder="手机号"
            v-model="form.username"
            @blur="
              () => {
                $refs.phone.onFieldBlur()
              }
            "
            @search="onSearch"
          >
            <a-button slot="enterButton" :disabled="isDisabled">
              {{ buttonName }}
            </a-button>
            <!-- <a-input
            placeholder="手机号"
            v-model="form.username"
            @blur="
              () => {
                $refs.phone.onFieldBlur();
              }
            ">
            <a-icon type="mobile" slot="prefix"/>
          </a-input> -->
          </a-input-search>
        </a-form-model-item>
        <a-row :gutter="10">
          <a-col>
            <a-form-model-item ref="checkCode" prop="checkCode">
              <a-input
                allow-clear
                placeholder="短信验证码"
                v-model="form.checkCode"
                @blur="
                  () => {
                    $refs.checkCode.onFieldBlur()
                  }
                "
              >
                <a-icon type="safety" slot="prefix" />
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <!-- <a-button class="code_btn" @click="send_captcha" :disabled="isDisabled">{{ buttonName }}</a-button> -->
          </a-col>
        </a-row>

        <a-form-model-item ref="password" prop="password">
          <a-input-password
            allow-clear
            type="password"
            placeholder="输入新密码"
            v-model="form.password"
            @blur="
              () => {
                $refs.password.onFieldBlur()
              }
            "
          >
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item ref="passwordAgain" prop="passwordAgain">
          <a-input-password
            allow-clear
            type="password"
            placeholder="确认新密码"
            v-model="form.passwordAgain"
            @blur="
              () => {
                $refs.passwordAgain.onFieldBlur()
              }
            "
          >
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item>
          <a-row :gutter="20">
            <a-col :span="12" style="text-align: center">
              <router-link to="/login" tag="div">
                <a-button @click="resetForm" class="forget_btn1" style="color: blue"> 返回登录页 </a-button>
              </router-link>
            </a-col>
            <a-col :span="12">
              <a-button type="primary" @click="onSubmit" class="forget_btn"> 确认重置密码 </a-button>
            </a-col>
          </a-row>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { forgePassword, getSmsCaptcha } from '@/api/login.js'
import reg from '@/utils/reg'

export default {
  data () {
    const validatorPhone = (rule, value, callback) => {
      // 如果为空值，就抛出错误
      if (!value) {
        callback(new Error('请输入手机号'))
      } else {
        // 正则判断手机号格式的格式，正则判断失败抛出错误，否则直接callback()
        if (!reg.phone.pattern.test(value)) {
          callback(new Error('手机号格式不正确'))
        } else {
          callback()
        }
      }
    }
    return {
      CountDown: 60, // 倒计时
      buttonName: '获取验证码', // 按钮初始化文字
      isDisabled: false, // 是否禁用按钮
      labelCol: { span: 4 },
      wrapperCol: { span: 24 },
      other: '',
      form: {},
      rules: {
        // username: [
        //   { required: true, message: '请输入用户名', trigger: 'blur' }
        // ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: reg.password.pattern, message: reg.password.msg }
        ],
        passwordAgain: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: this.validPasswordAgain, trigger: 'blur' }
        ],
        username: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        { pattern: reg.phone.pattern, message: reg.phone.msg }
      ],
        checkCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
        // validator对应手机格式验证方法（注意必须要有callback）
        // trigger对应触发验证的条件。取值有change(表单值改变时触发)；blur(表单元素失去焦点时触发)
      },
      logoPath: ''
    }
  },
  created () {
    const logoName = process.env.NODE_ENV === 'development' ? 'logo-dev' : 'logo'
    this.logoPath = require(`@/assets/denglu/${logoName}.png`)
  },
  methods: {
    validPasswordAgain (rule, value, callback) {
      if (!this.form.password) {
        callback(new Error('请先输入密码'))
      } else {
        if (this.form.password !== value) {
          callback(new Error('两次密码不一致，请重新输入'))
        } else {
          callback()
        }
      }
    },
    onSearch () {
      const phoneReg = /^1[3456789]\d{9}$/
      if (this.form.username) {
        if (phoneReg.test(this.form.username)) {
          // 先把按钮禁止，并进行倒计时，防止网速慢的用户重复点击
          this.isDisabled = true
          const timerId = setInterval(() => {
            this.CountDown--
            this.buttonName = this.CountDown + '秒后重试'
            if (this.CountDown <= 0) {
              clearInterval(timerId)
              this.buttonName = '重新发送'
              this.CountDown = 60
              this.isDisabled = false
            }
          }, 1000)
          const params = {
            phone: this.form.username,
            configId: 3
          }
          getSmsCaptcha(params)
            .then((res) => {
              if (res.success) {
                this.$message.success('发送成功')
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          this.$message.error('请检查手机号是否正确')
        }
      } else {
        this.$message.error('请输入手机号')
      }
    },
    onSubmit () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          forgePassword(this.form)
            .then((res) => {
              if (res.errMessage) {
                this.$message.success(res.errMessage)
              } else {
                this.$message.success('重置密码成功')
                this.$router.push({
                  name: 'login'
                })
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.forget_btn {
  border: 0;
  width: 100%;
  height: 40px;
}
.forget_btn1 {
  border: 0;
  height: 40px;
}
.p {
  width: 74px;
  height: 25px;
  font-size: 18px;
  margin-top: 40px;
  line-height: 18px;
  color: #333333;
  opacity: 1;
}
</style>
<style lang="less">
.tab {
  width: 1100px;
  height: 610px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #ffffff;
  .left img {
    width: 500px;
    height: 610px;
    float: left;
  }
  .FormModel {
    width: 600px;
    height: 610px;
    border-radius: 0 8px 8px 0;
    background: #ffffff;
    float: left;
    padding: 0 40px;
    img {
      display: block;
      width: 300px;
      height: 64.47px;
      margin: 33px auto 20px;
    }
  }
}

.login-tab {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}
//密码显示隐藏按钮
// .anticon svg{
//   display: none;
// }
.code_btn {
  height: 20px;
}
</style>
