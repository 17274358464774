var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab"},[_vm._m(0),_c('div',{staticClass:"FormModel"},[_c('img',{attrs:{"src":_vm.logoPath}}),_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"labelAlign":"left","wrapper-col":_vm.wrapperCol}},[_c('a-form-model-item',{attrs:{"labelCol":{
          span: 5,
        },"wrapper-col":{
          span: 19,
        },"label":"省市区域","prop":"location"}},[_c('a-cascader',{attrs:{"options":_vm.locationTree,"placeholder":"请选择省市区域","fieldNames":{
            label: 'district',
            value: 'district',
            children: 'children',
          }},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}})],1),_c('a-form-model-item',{attrs:{"label":"服务码（选填）","labelCol":{
          span: 7,
        },"wrapper-col":{
          span: 17,
        },"prop":"code"}},[_c('a-input',{attrs:{"placeholder":"服务码（选填）"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1),_c('a-form-model-item',{ref:"phone",attrs:{"prop":"phone"}},[_c('a-input-search',{attrs:{"allow-clear":"","placeholder":"手机号"},on:{"search":_vm.onSearch,"blur":function () {
              _vm.$refs.phone.onFieldBlur()
            }},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}},[_c('a-button',{attrs:{"slot":"enterButton","disabled":_vm.isDisabled},slot:"enterButton"},[_vm._v(_vm._s(_vm.buttonName))])],1)],1),_c('a-form-model-item',{ref:"checkCode",attrs:{"prop":"checkCode"}},[_c('a-input',{attrs:{"allow-clear":"","placeholder":"短信验证码"},on:{"blur":function () {
              _vm.$refs.checkCode.onFieldBlur()
            }},model:{value:(_vm.form.checkCode),callback:function ($$v) {_vm.$set(_vm.form, "checkCode", $$v)},expression:"form.checkCode"}})],1),_c('a-form-model-item',{ref:"password",attrs:{"prop":"password"}},[_c('a-input-password',{attrs:{"type":"password","allow-clear":"","placeholder":"输入新密码"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('a-form-model-item',{ref:"passwordAgain",attrs:{"prop":"passwordAgain"}},[_c('a-input-password',{attrs:{"allow-clear":"","type":"password","placeholder":"确认新密码"},on:{"blur":function () {
              _vm.$refs.passwordAgain.onFieldBlur()
            }},model:{value:(_vm.form.passwordAgain),callback:function ($$v) {_vm.$set(_vm.form, "passwordAgain", $$v)},expression:"form.passwordAgain"}})],1),_c('a-form-model-item',[_c('a-row',{attrs:{"type":"flex","justify":"center"}},[_c('a-col',{staticClass:"position_row",attrs:{"span":24}},[_c('div',{staticClass:"register_checkbox"},[_c('a-row',[_c('a-col',{attrs:{"span":2}},[_c('a-checkbox',{staticClass:"checkbox",model:{value:(_vm.isChoose),callback:function ($$v) {_vm.isChoose=$$v},expression:"isChoose"}})],1),_c('a-col',{attrs:{"span":22}},[_c('div',{staticClass:"register_tips"},[_vm._v(" 本人已认真阅读并认可 "),_c('a',{staticStyle:{"color":"#1677ff"},attrs:{"href":"https://app.agw-gcy.net/sgb/用户服务协议.html","target":"_blank"}},[_vm._v("《用户服务协议》")]),_vm._v("及 "),_c('a',{staticStyle:{"color":"#1677ff"},attrs:{"href":"https://app.agw-gcy.net/sgb/增值服务协议.html","target":"_blank"}},[_vm._v("《增值服务协议》")])])])],1)],1)]),_c('a-col',{attrs:{"span":24}},[_c('div',{staticClass:"register_btn"},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('router-link',{attrs:{"to":"/login"}},[_c('div',{staticClass:"register_btn_text"},[_vm._v("返回登录页")])])],1),_c('a-col',{attrs:{"span":12}},[_c('a-button',{staticStyle:{"width":"200px","height":"40px"},attrs:{"type":"primary","loading":_vm.btnLoading},on:{"click":_vm.onSubmit}},[_vm._v("提交注册")])],1)],1)],1)])],1)],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("@/assets/denglu/login-bg.jpg")}})])}]

export { render, staticRenderFns }